import createAccordian from './lib/accordian';
import burgerMenu from './lib/burger-menu';
import createTabs from './lib/tabbed-sections';
//import slider from './utils/slides';

// FIXME only run accordian and tabs on faq page
// FIXME prettier settings remove 'unused' variables/imports
window.addEventListener('load', () => {
  // console.log(burgerMenu);
  createAccordian();

  if (document.querySelector('.tabbed')) {
    createTabs();
  }
});
